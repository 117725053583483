import { styled } from '@mui/system';
import { mediaColumnNumber } from '@utils/media';

const MediaGridItem = styled('div')(({ size, theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
        width: `${100 / mediaColumnNumber.xs[size]}%`,
    },
    [theme.breakpoints.up('sm')]: {
        width: `${100 / mediaColumnNumber.sm[size]}%`,
    },
    [theme.breakpoints.up('md')]: {
        width: `${100 / mediaColumnNumber.md[size]}%`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `${100 / mediaColumnNumber.lg[size]}%`,
    },
    [theme.breakpoints.up('xl')]: {
        width: `${100 / mediaColumnNumber.xl[size]}%`,
    },
}));

export default MediaGridItem;
