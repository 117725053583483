import React, { useCallback, useMemo } from 'react';

import { styled } from '@mui/system';
import { useIllustration } from '@utils/illustrations';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Button from '@ui/inputs/Button';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';
import ErrorBanner from '@components/Error/ErrorBanner';
import MediaCard from '@components/Media/MediaCard';

import MediaGridContainer from './MediaGridContainer';
import NormalMediaGridList from './NormalMediaGridList';

const GridContainer = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(8),
}));

const HeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(3),
}));

const MediaGridTitle = styled(Typography)({
    flexGrow: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const MediaGridList = ({
    mediaList,
    totalCount,
    onLoadMoreClick,
    title,
    linkToAll,
    size,
    loading,
    forcedMaxItem,
    titleComponent,
    oneLiner,
    hasNextPage,
    extended,
}) => {
    const cms = useCms();
    const data = useMemo(() => mediaList || [], [mediaList]);
    const dataCount = data.length;
    const hasData = dataCount > 0;
    const { emptyMyList } = useIllustration();

    const listItemContent = useCallback(
        (index, item) => {
            return <MediaCard mediaData={item || data[index]} size={size} extended={extended} />;
        },
        [data, extended, size]
    );

    const Item = useMemo(() => React.forwardRef((props, ref) => <div {...props} ref={ref} size={size} />), [size]);

    if (linkToAll && !totalCount) {
        return null;
    }

    return (
        <GridContainer>
            {title && (
                <HeaderContainer>
                    <MediaGridTitle variant="h4" component={titleComponent || 'h2'}>
                        {title}
                    </MediaGridTitle>
                </HeaderContainer>
            )}
            {hasData ? (
                <>
                    <MediaGridContainer size={size}>
                        <NormalMediaGridList
                            data={data}
                            Item={Item}
                            listItemContent={listItemContent}
                            size={size}
                            totalCount={totalCount}
                            linkToAll={linkToAll}
                            forcedMaxItem={forcedMaxItem}
                            oneLiner={oneLiner}
                        />
                    </MediaGridContainer>
                    {!linkToAll && hasNextPage && !forcedMaxItem && (
                        <Box textAlign="center" mt={3}>
                            <Button variant="text" loading={loading} onClick={() => onLoadMoreClick()}>
                                {cms[571]}
                            </Button>
                        </Box>
                    )}
                </>
            ) : (
                <ErrorBanner title={cms[572]} description={cms[573]} src={emptyMyList.src} />
            )}
        </GridContainer>
    );
};

MediaGridList.defaultProps = {
    size: 'normal',
    extended: false,
};

MediaGridList.propTypes = {
    mediaList: PropTypes.array,
    loading: PropTypes.bool,
    totalCount: PropTypes.number,
    title: PropTypes.string,
    linkToAll: PropTypes.string,
    mediaType: PropTypes.string,
    onLoadMoreClick: PropTypes.func,
    size: PropTypes.oneOf(['small', 'normal', 'large', 'xLarge']),
    forcedMaxItem: PropTypes.number,
    titleComponent: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    oneLiner: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    extended: PropTypes.bool,
};

export default MediaGridList;
