import React, { useEffect, useMemo } from 'react';

import { lighten, styled } from '@mui/system';
import { getThumbnail, imgType } from '@utils/asset';
import { useIllustration } from '@utils/illustrations';
import { isContentLocked, mediaTypes } from '@utils/media';
import { useRouter } from 'next/router';

import Link from '@ui/link/Link';
import Typography from '@ui/Typography';

import EpisodeCompactCard from './EpisodeCompactCard';
import MediaCardContainer from './mediaCardElements/MediaCardContainer';
import MediaCardCover from './mediaCardElements/MediaCardCover';

const MediaTitle = styled(Typography, {
    shouldForwardProp: prop => prop !== 'mediaType' && prop !== 'align' && prop !== 'lines',
})(({ mediaType, align, lines }) => ({
    overflow: 'hidden',
    transition: 'all 0.2s',
    textAlign: align || (mediaType !== mediaTypes.host ? 'left' : 'center'),
    WebkitLineClamp: lines || 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
    fontWeight: 'bold',
}));

const MediaSubtitle = styled(Typography, {
    shouldForwardProp: prop => prop !== 'align' && prop !== 'lines',
})(({ align, lines }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    transition: 'all 0.2s',
    textAlign: align || 'left',

    WebkitLineClamp: lines || 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
}));

const MediaMeta = styled('div', {
    shouldForwardProp: prop => prop !== 'extended',
})(({ theme, extended }) => ({
    padding: extended ? `${theme.spacing(2)} ${theme.spacing(2)}` : '0.5rem 0 0 0',
    background: extended ? lighten(theme.palette.background.appBar, 0.1) : 'transparent',
    backdropFilter: extended ? 'blur(60px) saturate(200%)' : 'none',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
}));

const MediaCard = ({ mediaData, extended }) => {
    const {
        link,
        forcedImage,
        hosts,
        image,
        slug,
        title,
        type,
        parentMedia,
        description,
        position: { completeness } = {},
    } = mediaData || {};
    const router = useRouter();
    const { podcastImage } = useIllustration();
    const coverImg =
        forcedImage ||
        getThumbnail(image, imgType.thumbnail) ||
        getThumbnail(parentMedia?.image, imgType.thumbnail) ||
        podcastImage?.src;
    const isExclusive = mediaData?.properties?.isExclusive || mediaData?.parentMedia?.properties?.isExclusive;
    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);

    useEffect(() => {
        router.prefetch(`/${slug}`);
    }, [router, slug]);

    return (
        <>
            {type === 'episode' ? (
                <EpisodeCompactCard mediaData={mediaData} extended={extended} />
            ) : (
                <Link href={link || `/${slug}`} underline="none">
                    <MediaCardContainer extended={extended} backgroundImg={coverImg}>
                        <MediaCardCover
                            type={type}
                            url={coverImg}
                            alt={title}
                            completeness={completeness}
                            gutterBottom
                            isExclusive={isExclusive}
                            isLocked={isLockedMemo}
                            extended={extended}
                        />
                        <MediaMeta extended={extended}>
                            {!extended && (
                                <>
                                    <MediaTitle variant="h5" component="h3" mediaType={type} align="left">
                                        {title}
                                    </MediaTitle>
                                    {hosts?.length > 0 && (
                                        <MediaSubtitle variant="body2" align={extended ? 'center' : 'left'}>
                                            {hosts?.map(({ title }) => title)?.join(', ')}
                                        </MediaSubtitle>
                                    )}
                                </>
                            )}
                            {extended && (
                                <MediaSubtitle variant="body1" align="center" lines={5}>
                                    {description}
                                </MediaSubtitle>
                            )}
                        </MediaMeta>
                    </MediaCardContainer>
                </Link>
            )}
        </>
    );
};

export default MediaCard;
