import React from 'react';

import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import Box from '@ui/Box';

const CoverSkeleton = styled(Skeleton, {
    shouldForwardProp: prop => prop !== 'type',
})(({ theme, type }) => ({
    position: 'relative',
    display: 'block',
    minWidth: '100%',
    height: '100%',
    borderRadius: type !== 'host' ? theme.shape.borderRadius : '50%',
    '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
        position: 'relative',
    },
}));

const EpisodeSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
}));

const MediaCardLoading = ({ type }) => {
    if (type === 'episode') {
        return <EpisodeSkeleton variant="ractangle" height={64} />;
    }

    return (
        <div>
            <CoverSkeleton type={type} variant="rectangle" />
            <Box pt={1}>
                <Skeleton />
                <Skeleton />
            </Box>
        </div>
    );
};

export default MediaCardLoading;
