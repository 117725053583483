import { styled } from '@mui/system';

const MediaCardContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'backgroundImg' && prop !== 'extended',
})(({ theme, extended, backgroundImg }) => ({
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
        '& .media-cover': {
            transform: extended ? 'none' : 'scale(1.1)',
            boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
            '& *': {
                color: theme.palette.text.primary,
            },
        },
        '& .media-cover-gradient': {
            background: 'none',
        },
    },
    // background: extended ? background || theme.palette.background.paper : 'transparent',
    backgroundImage: extended && backgroundImg ? `url(${backgroundImg})` : 'none',
    // backgroundSize: 'cover',
    borderRadius: theme.shape.borderRadius,
    overflow: extended ? 'hidden' : 'visible',
}));

export default MediaCardContainer;
