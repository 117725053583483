import React from 'react';

import Box from '@ui/Box';

const MaxWidthBox = ({ children, ...rest }) => {
    return (
        <Box sx={{ maxWidth: 1240 }} {...rest}>
            {children}
        </Box>
    );
};

export default MaxWidthBox;
