import React from 'react';

import { styled } from '@mui/system';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import { H2 } from '@ui/heading';
import Typography from '@ui/Typography';

const ErrorContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}));

const ErrorTextContainer = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(4),
        textAlign: 'left',
    },
}));

const ErrorTitle = styled(H2)(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.text.primary,
    margin: 0,
    marginBottom: theme.spacing(1),
}));

const ErrorBanner = ({ src, title, description }) => {
    return (
        <ErrorContainer>
            <Image src={src} height={150} width={150} alt="" />
            <ErrorTextContainer>
                <ErrorTitle>{title}</ErrorTitle>
                <Typography variant="body1">{description}</Typography>
            </ErrorTextContainer>
        </ErrorContainer>
    );
};

ErrorBanner.propTypes = {
    src: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default ErrorBanner;
