import React from 'react';

import { gridListLotSize, gridListPageSize, useListQuery } from '@utils/query';

import MediaGridList from '../MediaGridList';
import MediaGridListLoading from '../MediaGridListLoading';

const ConnectedMediaGridList = ({
    isNew,
    byChildren,
    noTitle,
    title,
    initialData,
    linkToAll,
    type,
    hostId,
    categoryId,
    size,
    since,
    oneLiner,
    maxItems,
    isHighlighted,
    isLocked,
    isForeignLanguage,
    ...rest
}) => {
    const subFix = isNew ? '/new' : isLocked ? '/locked' : '';
    const [mediaList, loading, totalCount, loadMore, _reload, _error, pageInfo] = useListQuery({
        endpoint: `/media${subFix}`,
        filters: {
            type,
            categoryId: categoryId || '',
            hostId: hostId || '',
            byChildren: byChildren || '',
            ...(since && { since }),
            ...(isHighlighted && { isHighlighted }),
            ...(isForeignLanguage && { isForeignLanguage }),
        },
        pageSize: maxItems || (linkToAll ? gridListLotSize : gridListPageSize),
        initialData,
        muteNotifications: true,
    });

    if (mediaList?.length === 0) {
        if (loading) {
            return <MediaGridListLoading noTitle={noTitle} size={size || 'normal'} type={type} oneLiner={oneLiner} />;
        }

        return null;
    }

    return (
        <MediaGridList
            mediaList={mediaList}
            totalCount={totalCount}
            title={noTitle ? null : title}
            onLoadMoreClick={loadMore}
            linkToAll={linkToAll}
            loading={loading}
            size={size}
            oneLiner={oneLiner}
            hasNextPage={maxItems ? false : pageInfo?.hasNextPage}
            {...rest}
        />
    );
};

export default ConnectedMediaGridList;
