import React from 'react';

import { useIllustration } from '@utils/illustrations';

import useCms from '@components/Cms/useCms';

import useMediaGridListCount from './hooks/useMediaGridListCount';

const NormalMediaGridList = ({ data, Item, listItemContent, size, totalCount, linkToAll, forcedMaxItem, oneLiner }) => {
    const cms = useCms();
    const wrapCount = useMediaGridListCount(size, oneLiner);
    const cardCount = forcedMaxItem || (linkToAll ? wrapCount : data.length);
    const { loadMoreImage } = useIllustration();
    const { type: loadMoreType } = data[0];

    const hasMore = !!totalCount && totalCount > cardCount;
    const lastIndex = cardCount - 1;

    return data.slice(0, cardCount).map((item, index) => {
        return index === lastIndex && hasMore && linkToAll ? (
            <Item key={lastIndex}>
                {listItemContent(lastIndex, {
                    link: linkToAll,
                    forcedImage: loadMoreImage.src,
                    title: cms[589],
                    type: loadMoreType,
                })}
            </Item>
        ) : (
            <Item key={index}>{listItemContent(index, item)}</Item>
        );
    });
};

export default NormalMediaGridList;
