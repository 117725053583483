import { useCallback, useEffect, useMemo } from 'react';

import { getLinks } from 'cache';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import useApi from '@components/api/useApi';

import { objectToParamString } from './browser';
import { toastApiError } from './toast';

export const listPageSize = 20;
export const gridListLotSize = 16;
export const gridListPageSize = 24;

export const useListQuery = ({
    endpoint,
    filters,
    pageSize,
    defaultErrorMessage,
    initialData,
    disabled,
    muteNotifications,
    reversed,
    revalidateFirstPage = false,
}) => {
    const api = useApi();

    const fetcher = useCallback(
        async (index, path) => {
            if (!!initialData && !index) {
                return initialData;
            }

            const data = await api.fetcher(path);

            return data;
        },
        [api, initialData]
    );

    const getKey = useCallback(
        (index, previousPageData) => {
            if (disabled) {
                return null;
            }

            const { pageInfo, edges, totalCount } = previousPageData || {};
            const { endCursor, hasNextPage } = pageInfo || {};

            if (previousPageData && !(hasNextPage || totalCount > edges.length)) {
                return null;
            }

            const { after, ...rest } = filters || {};

            const direction = reversed ? 'before' : 'after';

            return [
                index,
                `${endpoint}?first=${pageSize || listPageSize}&${direction}=${endCursor || after || ''}${
                    rest ? `&${objectToParamString(rest, true)}` : ''
                }`,
            ];
        },
        [endpoint, filters, pageSize, disabled, reversed]
    );

    // https://swr.vercel.app/docs/pagination#parameters
    const { data, isValidating, mutate, error, size, setSize } = useSWRInfinite(getKey, fetcher, {
        revalidateFirstPage,
    });

    useEffect(() => {
        if (error && !muteNotifications) {
            toastApiError(error, defaultErrorMessage);
        }
    }, [error, defaultErrorMessage, muteNotifications]);

    const processedData = useMemo(
        () =>
            (data || []).reduce((acc, current) => {
                return [...acc, ...(current?.edges || []).map(({ cursor, node }) => ({ ...node, cursor }))];
            }, []),
        [data]
    );

    const loadMore = useCallback(() => {
        setSize(size + 1);
    }, [setSize, size]);

    const totalCount = data?.[0]?.totalCount;
    const pageInfo = data?.[data?.length - 1]?.pageInfo;

    return [processedData, isValidating, totalCount, loadMore, mutate, error, pageInfo];
};

export const useQuery = ({ endpoint, disabled, redirectId, redirectLink, disableRedirect, muteNotifications }) => {
    const router = useRouter();
    const { data, isValidating, mutate, error } = useSWR(disabled ? null : endpoint);

    useEffect(() => {
        if (!error) {
            return;
        }

        if (error?.statusCode !== 404 && !muteNotifications) {
            toastApiError(error);
        }

        if (!disableRedirect && error?.statusCode === 404) {
            router.push(redirectLink || getLinks(redirectId || 'home'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, redirectId]);

    return [data, isValidating, mutate, error];
};
