import { useEffect, useState } from 'react';

import { mediaColumnNumber } from '@utils/media';
import { useWidth } from '@utils/screenSize';

const getCardCount = (width, size, oneLiner) => {
    const rows = {
        xs: 3,
        sm: 3,
        md: 2,
        lg: 2,
        xl: 2,
        oneLiner: 1,
        default: 1,
    };

    const modifier = oneLiner ? rows.oneLiner : rows[width] || rows.default;
    return mediaColumnNumber[width][size] * modifier;
};

const useMediaGridListCount = (size, oneLiner = false) => {
    const width = useWidth();

    const [cardCount, setCardCount] = useState(getCardCount(width, size, oneLiner));

    useEffect(() => {
        setCardCount(getCardCount(width, size, oneLiner));
    }, [width, size, oneLiner]);

    return cardCount;
};

export default useMediaGridListCount;
