import {styled} from '@mui/system';
import {mediaColumnNumber} from '@utils/media';

import Box from '@ui/Box';

const MediaGridContainer = styled(Box, {
    shouldForwardProp: prop => prop !== 'size',
})(({theme, size}) => ({
    display: 'grid',

    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
        gridTemplateColumns: `repeat(${mediaColumnNumber.xs[size]}, minmax(0, 1fr))`,
    },
    [theme.breakpoints.up('sm')]: {
        gap: theme.spacing(2),
        gridTemplateColumns: `repeat(${mediaColumnNumber.sm[size]}, minmax(0, 1fr))`,
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(${mediaColumnNumber.md[size]}, minmax(0, 1fr))`,
    },
    [theme.breakpoints.up('lg')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: `repeat(${mediaColumnNumber.lg[size]}, minmax(0, 1fr))`,
    },
    [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: `repeat(${mediaColumnNumber.xl[size]}, minmax(0, 1fr))`,
    },
}));

export default MediaGridContainer;
