import React, { useMemo } from 'react';

import { Box, Skeleton } from '@mui/material';

import useMediaGridListCount from './hooks/useMediaGridListCount';
import MediaCardLoading from './MediaCardLoading';
import MediaGridItem from './mediaGridElements/MediaGridItem';

const MediaGridListLoading = ({ size, noTitle, type, oneLiner }) => {
    const cardCount = useMediaGridListCount(size, oneLiner);

    const cards = useMemo(
        () =>
            Array.from({ length: cardCount }, (_, index) => (
                <MediaGridItem key={index} size={size}>
                    <MediaCardLoading type={type} />
                </MediaGridItem>
            )),
        [cardCount, size, type]
    );

    const title = useMemo(
        () =>
            noTitle ? null : (
                <Box pb={3}>
                    <Skeleton variant="text" height={19} width={'25%'} />
                </Box>
            ),
        [noTitle]
    );

    return (
        <Box mb={5}>
            {title}
            <Box mr={-1} ml={-1} display="flex" flexWrap="wrap">
                {cards}
            </Box>
        </Box>
    );
};

export default MediaGridListLoading;
